
  .logo-slider{
    background-color: #29c0bb;
    margin-top: 20px;
    width: auto;
  }
  
  .logo-slider img {
    height: 200px;
    width: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
    animation: scroll 60s linear infinite;
  }
  
  .logo-slider h2{
    font-size: xx-large;
    padding-bottom: 30px;
    padding-top: 20px;
  }
  .logo-slider .slide-track {
    width: 100%;
    display: flex;
    overflow: hidden;
  }
  
  .logo-slider .slider {
    margin-top: 70px;
    background-color: whitesmoke;
    padding: 2em 2em;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-1000%);
    }
  }

  @media (max-width: 768px) {

    .logo-slider{
      background-color: #29c0bb;
      margin-top: 20px;
      width: auto;
    }
    
    .logo-slider img {
      height: 100px;
      width: 100px;
      margin-top: 10px;
      margin-bottom: 10px;
      animation: scroll 60s linear infinite;
    }
    
    .logo-slider h2{
      font-size: small;
      padding-bottom: 30px;
      padding-top: 20px;
    }
    .logo-slider .slide-track {
      width: 100%;
      display: flex;
      overflow: hidden;
    }
    
    .logo-slider .slider {
      margin-top: 70px;
      background-color: whitesmoke;
      padding: 1em 1em;
    }
  }
  
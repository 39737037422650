

.App {
  text-align: center;
  width: auto;
  overflow: hidden;
}

.App-logo {
  width: 100%;
  pointer-events: none;
}

.para {
  font-size: 26px;
  padding-top: 0;
  text-align: center;
}

.App-header {
  background-color: #ffffff;
  padding-top: 4rem;
  display: block ;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}


.card-container {
  display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    /* Additional styling (e.g., width, height, outline) */
    font-size: 1.5rem;
    padding-top: 3rem;
    width: auto;
}
.card-container p{
  text-align: center;
}
@media (max-width: 768px) {
  
  
.App {
  text-align: center;
  width: auto;
  overflow: hidden;
}

.App-logo {
  width: 100%;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  padding-top: 4rem;
  display: block ;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}


.card-container {
  display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    /* Additional styling (e.g., width, height, outline) */
    font-size: 1.2rem;
    padding-top: 1rem;
    width: auto;
}
.para {
  font-size: medium;
  padding-top: 0;
}
.card-container p{
  text-align: center;
}

}

/* Assuming you have a CSS file (e.g., Card.css) where you define your styles */

/* Styles for the card container */
.card-container {
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    gap: 20px; /* Adjust the gap between cards */
  }

  
  .para{
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
  }
  /* Styles for individual cards */
  .card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    transition: transform 1s ease, background-color 1s ease, box-shadow 1s ease;
    
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px; /* Set your desired card width */
    height: 350px;
  }
  .card:hover {
    background-color: #f7f4f4; /* Change background color on hover */
    transform: scale(1.05); /* Slightly enlarge the card */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
  /* Styles for the card image */
  .card img {
    max-width: 100%;
    height: 200px;
    width: auto;
    border-radius: 4px;
  }
  
  /* Styles for the card title */
  .card h3 {
    font-size: 18px;
    margin: 10px 0;
  }
  
  /* Styles for the card description */
  .card p {
    font-size: 14px;
    color: #555;
  }

  /* Add a fade-in effect to the cards */
.fade-in-section {
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    transform: translateY(20vh); /* Start slightly below the viewport */
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
}
.fade-in-section.is-visible {
    display: flex;
    flex-wrap: wrap;
    opacity: 1;
    transform: none; /* Reset the transform */
    visibility: visible;
}

@media (max-width: 768px) {
  .card-container {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    gap: 10px; /* Adjust the gap between cards */
  }

  .para{
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
  }
  /* Styles for individual cards */
  .card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    transition: transform 1s ease, background-color 1s ease, box-shadow 1s ease;
    
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 150px; /* Set your desired card width */
    height: 250px;
  }
  .card:hover {
    background-color: #f7f4f4; /* Change background color on hover */
    transform: scale(1.05); /* Slightly enlarge the card */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
  /* Styles for the card image */
  .card img {
    max-width: 100%;
    height: 100px;
    width: auto;
    border-radius: 4px;
  }
  
  /* Styles for the card title */
  .card h3 {
    font-size: 15px;
    margin: 10px 0;
  }
  
  /* Styles for the card description */
  .card p {
    font-size: 9px;
    color: #555;
  }

  /* Add a fade-in effect to the cards */
.fade-in-section {
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    transform: translateY(20vh); /* Start slightly below the viewport */
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
}
.fade-in-section.is-visible {
    display: flex;
    flex-wrap: wrap;
    opacity: 1;
    transform: none; /* Reset the transform */
    visibility: visible;
}
}
.container-form {
        display: flex; /* Make the form and info side-by-side */
        width: 100%; /* Adjust width as needed */
        height: auto;
        margin: 50px auto;
        padding: 40px;
        border: 1px solid #ddd;
        border-radius: 5px;
       
      }
      
      h1 {
        text-align: center;
        margin-bottom: 20px;
      }
      
      label {
        display: block;
        margin-bottom: 5px;
      }
      
      input,
      textarea {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 3px;
        box-sizing:content-box;
        margin-bottom: 15px;
      }
      
      textarea {
        height: 100px;
        resize: vertical;
      }
      
      button {
        background-color: #4CAF50;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 10px;
      }
      
      button:hover {
        background-color: #3e8e41;
      }
      .form-section{
        flex: 0.75; /* Make the info section take half the width */
        padding: 20px;
        width: 50%;
        background-color: #f2f2f2;
      }
      /* Styles for the information section */
      .info-section {
        flex: 1.25; /* Make the info section take half the width */
        padding: 20px;
        
        width: 50%;
      }
      
      .form-section form{
        margin-left: 10%;
        margin-right: 10%;
      }

      .info-section h2 {
        margin-bottom: 10px;
      }
      
      .info-section iframe {
        border: none;
        width: 70%;
        height: 50%;
      }
      
      .info-section p {
        margin-bottom: 5px;
      }
      

      @media (max-width: 768px){
        .container-form {
          display: block; /* Make the form and info side-by-side */
          width: auto; /* Adjust width as needed */
          height: auto;
          margin: 20px auto;
          padding-top: 4rem;
          border: 1px solid #ddd;
          border-radius: 5px;
         
        }
        
        h1 {
          text-align: center;
          margin-bottom: 20px;
        }
        
        label {
          text-align: left;
          padding-left: 5px;
          display: block;
          margin-bottom: 5px;
        }
        
        input,
        textarea {
          width: 90%;
          padding-top: 20px auto;
          border: 1px solid #ccc;
          border-radius: 3px;
          box-sizing:content-box;
          margin-bottom: 15px;
        }
        
        textarea {
          height: 100px;
          resize: vertical;
        }
        
        button {
          background-color: #4CAF50;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 10px;
        }
        
        button:hover {
          background-color: #3e8e41;
        }
        .form-section{
          flex: none; /* Make the info section take half the width */
          padding: 10px;
          width: 100%;
          background-color: #f2f2f2;
        }
        /* Styles for the information section */
        .info-section {
          flex: 0; /* Make the info section take half the width */
          padding: 20px;
          
          width: 100%;
        }
        
        .form-section form{
          margin-left: 5%;
          margin-right:5%;
        }
  
        .info-section h2 {
          margin-bottom: 10px;
        }
        .info-section {
          display: inline;
          padding: 0%;
          margin: 0%;
        }
        .info-section iframe {
          border: none;
          padding-top: 20px;
          width: 100%;
          height: 70%;

        }
        
        .info-section p {
          margin-bottom: 5px;
        }

      }

  
.footer {
    background-color: #f2f2f2;
    width: auto;
    color: #333;
  }
  
  /* Base styles for all screen sizes */
.container {
  width: 100%; /* Set width to 100% for full-width container */
  margin: 20px; 
  padding-bottom: 0%;
  
}

.list-unstyled li{
  margin-left: 30px;
  margin-right: 30px;
}

.row {
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-wrap: wrap; /* Allow rows to wrap on smaller screens */
  align-items: center;
}

/* Responsive styles for mobile devices (typically screens less than 768px wide) */
@media (max-width: 768px) {
  .col-md-4 {
    width: 100%; /* Set width to 100% for columns on mobile */
    padding: 0 20px; /* Adjust padding for smaller screens */
  }
}

/* Optional styles for larger screens (can be customized further) */
@media (min-width: 768px) {
  .col-md-4 {
    width: 33.33%; /* Maintain original width for medium screens */
  }
}
  
 
  .footer p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .footer h3 {
    margin-bottom: 15px;
    font-size: 18px;
    
  }
  
  .footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  
  .footer li a {
    color: #333;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }
  
  .footer li a:hover {
    color: #000;
  }
  
  .footer img {
    height: auto; /* Maintain desired height */
    width: 100%; /* Set width to 100% for responsiveness */
    object-fit: cover; /* Optional: Scale image to fill container while maintaining aspect ratio */
  }

  .copyright{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    border-top: 1px solid #ddd;
  }

  .reserved-rights {
    font-weight: bold;
    /* Add other styling for the text */
  }

  
  .social-icons li a {
   
    margin-right: 20px;
    font-size: 30px;
    color: #333; /* Adjust color as desired */
    text-decoration: none; /* Remove underlines */
    transition: color 0.3s ease; /* Add smooth color transition on hover */
  }
  
  .social-icons li a:hover {
    color: #29c0bb; /* Change color on hover */
  }

  
.social-icons {
  display: flex;
  list-style:square;
  padding: 0;
  margin: 0;
}

.social-icons li {
  margin: 0 5px;
  
}

@media (max-width: 768px) {

  
  .footer {
    background-color: #f2f2f2;
    width: 100%;
    color: #333;
  }
  
  /* Base styles for all screen sizes */
.container {
  width: 100%; /* Set width to 100% for full-width container */
  margin: 0%; 
  padding-bottom: 0%;
  padding-left: 0rem;
  
}

.list-unstyled li{
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;

  font-size: 12px;
}
.col-md-4{
  font-size: 12px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction:column;
}
.col-md-4 p{
  font-size: 12px;
  width: auto;
}

.row {
  width:95%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex; /* Allow rows to wrap on smaller screens */
  align-items: center;
}


  
 
  .footer p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .footer h3 {
    margin-bottom: 15px;
    font-size: 18px;
    
  }
  
  .footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  
  .footer li a {
    color: #333;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }
  
  .footer li a:hover {
    color: #000;
  }
  
  .footer img {
    height: auto; /* Maintain desired height */
    width: 50%; /* Set width to 100% for responsiveness */
    object-fit: cover; /* Optional: Scale image to fill container while maintaining aspect ratio */
  }

  .copyright{
    display: block;
    justify-content:baseline;
    align-items: center;
    margin-right: 0px;
    font-size: 9px;
    border-top: 1px solid #ddd;
  }

  .reserved-rights {
    font-weight: bold;
    /* Add other styling for the text */
  }

  .social-icons li{
    padding-left: 1.3rem;
  }
  .social-icons li a {
    padding-left: 1rem;
    font-size: 20px;
    color: #333; /* Adjust color as desired */
    text-decoration: none; /* Remove underlines */
    transition: color 0.3s ease; /* Add smooth color transition on hover */
  }
  
  .social-icons li a:hover {
    color: #29c0bb; /* Change color on hover */
  }



}

  
 
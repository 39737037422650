.navbar {
  display: grid;
  grid-template-columns: 1fr auto; /* One fraction for logo, auto for button */
  align-items: center;
  height: 4rem;
  background-color: #ffffff; /* Set your desired background color */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); /* Add a shadow */
  transition: top 0.01s ease-in-out; /* Smoother transition effect */
  position: fixed; /* Stick the navbar to the top */
  width: 100%; /* Full width */
  justify-content: center;
  top: 0; /* Initially at the top */
  z-index: 1000; /* Ensure the navbar is on top */
}


.logo img {
  width: 150px; /* Adjust the logo size */
  margin: 0 auto;
  flex-wrap: flex;
  padding-left: 100px;
}


.contact-btn {
  padding: 0.5rem 1rem; /* Increase padding for more space */
  background-color: #29c0bb; /* Adjust base color (optional) */
  color: white; /* Text color */
  border: none;
  margin-left: 20px;
  margin-right: 20px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  /* Add some attractive styles: */
  transition: background-color 1s ease-in-out; /* Smooth color change on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-decoration: none; /* Remove underline from text (for links) */
}

@media (max-width: 768px) {
  /* Styles for screens less than 768px wide */
  .contact-btn {
    padding: 0.5rem 0.5rem; /* Increase padding for more space */
    background-color: #29c0bb; /* Adjust base color (optional) */
    color: white; /* Text color */
    border: none;
    margin-left: 20px;
    margin-right: 20px;
    margin: 0 auto;
    font-size: 13px;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    /* Add some attractive styles: */
    transition: background-color 1s ease-in-out; /* Smooth color change on hover */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-decoration: none; /* Remove underline from text (for links) */
  }
}

/* Optional: Style for the link on hover (improved) */
.contact-btn:hover {
  background-color: #18afaa; /* Change background color on hover */
}
